<template>
  <acciones>
    <accion-primaria :label="$t('Editar')" icon="edit" :to="enlaceEditar" />

    <acciones-secundarias>
      <accion-eliminar @eliminar="eliminarSimulacion" :confirmacion="`¿Desea eliminar la simulación?`" />
    </acciones-secundarias>
  </acciones>
</template>
<script>
import * as apiSimulaciones from "@/services/simulaciones.js";
export default {
  props: {
    simulacion: Object,
  },
  computed: {
    enlaceEditar() {
      return this.$router.makeStickUrl(`/simulaciones/editar/${this.simulacion.id}`);
    },
  },
  methods: {
    async eliminarSimulacion() {
      await apiSimulaciones.eliminar(this.simulacion.id);
      this.$root.$emit("userMessage", "Simulación eliminada");
      this.$emit("simulacionEliminada", this.simulacion.id);
    },
  },
};
</script>
