<template>
  <div>
    <tabla-mobile :simulaciones="simulaciones" @changed="onChange" />
    <tabla-desktop :simulaciones="simulaciones" @changed="onChange" />
  </div>
</template>
<script>
import TablaMobile from "./TablaMobile.vue";
import TablaDesktop from "./TablaDesktop.vue";
export default {
  components: {
    TablaMobile,
    TablaDesktop,
  },
  props: {
    simulaciones: Array,
  },
  methods: {
    onChange(id) {
      this.$emit("changed", id);
    },
  },
};
</script>
