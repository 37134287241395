<template>
  <page-listado :title="`${$t('Simulación')}: ${adelantado.nombre} para ${rezagado.nombre}`" con-busqueda>
    <template slot="toolbar">
      <b-button variant="primary" size="sm" :to="enlaceCrear">
        <v-icon name="plus" width="16"></v-icon>
        {{ $t("Crear") }}
      </b-button>
    </template>

    <p>
      Desde el <b>{{ desde | fecha }}</b> hasta el <b>{{ hasta | fecha }}</b>
      <span v-if="zona">, Zona: {{ zona }}</span>
      <br />
      <b-button variant="warning" :to="enlaceVuelta">
        <v-icon name="arrow-left-circle" width="16"></v-icon>
        Volver al dashboard
      </b-button>
    </p>

    <tabla :simulaciones="simulaciones" @changed="simulacionEliminada"></tabla>

    <paginacion v-if="numeroPaginas > 1" :page-count="numeroPaginas" :page="pagina" :pageRange="3"></paginacion>
  </page-listado>
</template>

<script>
import * as apiIndicadores from "@/services/indicadores";
import * as apiSimulaciones from "@/services/simulaciones";
import Tabla from "./Tabla";
export default {
  components: {
    Tabla,
  },
  props: {
    cuadroMandoId: String,
    adelantadoId: String,
    rezagadoId: String,
    desde: String,
    hasta: String,
    zona: String,
  },
  computed: {
    myParams() {
      return new URLSearchParams(this.$route.query).toString();
    },
    enlaceCrear() {
      return `/simulaciones/crear?${this.myParams}`;
    },
    enlaceVuelta() {
      return `/cuadro-mando?${this.myParams}`;
    },
  },
  data() {
    return {
      adelantado: apiIndicadores.crear(),
      rezagado: apiIndicadores.crear(),
      tipoIndicador: Object.freeze({ MANUAL: 1, CHECKLIST: 3 }),
      simulaciones: [],
      numeroPaginas: 0,
      pagina: 1,
    };
  },
  methods: {
    async cargarIndicadores() {
      const [r1, r2] = await Promise.all([
        apiIndicadores.cargar(this.adelantadoId),
        apiIndicadores.cargar(this.rezagadoId),
      ]);

      this.adelantado = r1;
      this.rezagado = r2;
    },
    async listar() {
      const params = {
        adelantadoId: this.adelantadoId,
        rezagadoId: this.rezagadoId,
        filtros: {
          desde: this.desde,
          hasta: this.hasta,
          zona: this.zona,
        },
      };

      const resultado = await apiSimulaciones.listarParaRezagado(params);
      this.simulaciones = resultado.simulaciones;
      this.numeroPaginas = resultado.numeroPaginas;
      this.pagina = resultado.pagina;
    },
    simulacionEliminada(id) {
      this.listar();
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.cargarIndicadores();
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
