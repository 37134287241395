<template>
  <table class="listado table table-hover m-0 tabla-desktop">
    <thead class="bg-primary text-white">
      <th>{{ $t("Fecha") }}</th>
      <th>{{ $t("Zona") }}</th>
      <th>{{ $t("Valor") }}</th>
      <th title="Valor rezagado">{{ $t("V/Rezagado") }}</th>
      <th class="th-acciones">{{ $t("Acciones") }}</th>
    </thead>
    <tbody>
      <tr v-for="simulacion in simulaciones" v-bind:key="simulacion.id">
        <td>{{ simulacion.fecha | fecha }}</td>
        <td>{{ simulacion.zona }}</td>
        <td>{{ simulacion.valor }}</td>
        <td>{{ simulacion.valorRezagado }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <acciones-tabla :simulacion="simulacion" @simulacionEliminada="onSimulacionEliminada" />
        </td>
      </tr>
    </tbody>
    <sin-registros :count="simulaciones.length" :colspan="5"></sin-registros>
  </table>
</template>

<script>
import AccionesTabla from "./AccionesTabla.vue";

export default {
  components: {
    AccionesTabla,
  },
  props: {
    simulaciones: Array,
  },
  methods: {
    onSimulacionEliminada(id) {
      this.$emit("changed", id);
    },
  },
};
</script>
