import { render, staticRenderFns } from "./TablaMobile.vue?vue&type=template&id=bc94d1c4&"
import script from "./TablaMobile.vue?vue&type=script&lang=js&"
export * from "./TablaMobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports